import styled from "styled-components"
import { Link } from "gatsby"
import AvenueImg from "components/AvenueImg"
import { FlexCol, FlexRow, FlexGrid } from "../FlexGrid/index"

// import { FlexCol } from "../FlexGrid/index"

import { device } from "../../utils/breakpoints"

export const MenuItems = styled(Link)`
  font-weight: 600;
  font-size: 12px;
  color: #9cacae;
  letter-spacing: 0;
  line-height: 100%;
  text-decoration: none;
  margin-right: 30px;
  cursor: pointer;

  :last-child {
    margin-right: 0;
  }

  @media ${device.tablet} {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0;
  }

  :hover {
    color: ${props => props.theme.primaryColor};
    transition: 80ms;
  }
`

export const Copyright = styled.p`
  font-size: 16px;
  text-align: center;
  color: #000;

  a {
    text-decoration: underline;
    :hover {
      opacity: 0.6;
      transition: 80ms;
    }
  }
`
export const LogoContainer = styled.div`
  margin-top: 30px;

  @media ${device.tablet} {
    margin-left: 3rem;
    margin-top: 0;
  }

  svg {
    height: auto;
    :hover {
      opacity: 0.7;
      transition: 80ms;
    }
  }
`
export const Menu = styled.div`
  display: flex;
  align-items: flex-end;
`
export const Logo = styled.div`
  position: relative;
  a {
    display: flex;
  }

  @media ${device.tablet} {
    :before {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 1px;
      background-color: #9cacae;
      left: -20%;
    }
  }
  @media ${device.desktop} {
    :before {
      left: -25px;
    }
  }

  svg {
    height: auto;
    :hover {
      opacity: 0.7;
      transition: 80ms;
    }
  }
`
export const SocialAndLogo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: flex-start;
  }
  @media ${device.desktop} {
    justify-content: flex-end;
  }
`
export const Flag = styled(AvenueImg)`
  width: 60px;
  margin-right: 14px;
  display: inline-flex;
`

export const StatementGrid = styled(FlexGrid)`
  padding: 44px 0;
`
export const StatementCol = styled(FlexCol)`
  p {
    color: #052c20;
    font-size: 16px;
    margin: 0;
    padding-top: 10px;

    @media ${device.desktop} {
      font-size: 18px;
      padding-top: 15px;
    }
  }
`

export const IconRow = styled(FlexRow)`
  > div:first-child {
    order: 1;
  }

  > div:last-child {
    order: 0;
  }

  @media ${device.desktop} {
    padding-top: 40px;
    border-top: 1px solid rgba(60, 60, 60, 0.3);

    > div:first-child {
      order: 0;
    }
  }
`
export const Contact = styled.div`
  background: #f2efe9;
  overflow: hidden;
  padding: 40px 0;

  @media ${device.desktop} {
    padding: 50px 0;
  }
`

export const Heading = styled.h3`
  font-size: 22px;

  @media ${device.big} {
    font-size: 30px;
  }
`

export const ContactDetails = styled.div`
  @media ${device.big} {
    font-size: 18px;
  }

  a {
    text-decoration: underline;
  }

  &.address {
    width: 186px;

    a {
      display: block;
    }
  }
`
