import React from "react"
import { FlexGrid, FlexRow, FlexCol } from "../FlexGrid/index"
import { Link } from "gatsby"
import {
  Copyright,
  SocialAndLogo,
  Flag,
  StatementCol,
  StatementGrid,
  IconRow,
  Contact,
  ContactDetails,
  Heading,
} from "./footerMenuStyles"
import { SocialLinks } from "../SocialLinks/index"
import { useStaticQuery, graphql } from "gatsby"

var d = new Date()

const FooterMenu = ({ preview }) => {
  const data = useStaticQuery(
    graphql`
      query flags {
        tsflag: file(relativePath: { eq: "flag-ts.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        abflag: file(relativePath: { eq: "flag-aboriginal.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        wpgraphql {
          auburnOptions {
            siteSettings {
              email
              phoneNumber
              acknowledgement
              mapLink
              address
            }
          }
        }
      }
    `
  )
  

  const details =
    preview?.auburnOptions.siteSettings || data.wpgraphql.auburnOptions.siteSettings

  return (
    <>
      <Contact>
        <FlexGrid gap={[0, 30, 30]} rowGap={[30, 30, 0]}>
          <FlexRow>
            <FlexCol xs={12} md={6}>
              <Heading>Contact</Heading>
            </FlexCol>
            <FlexCol xs={12} md={3}>
              <ContactDetails className="address">
                {details.address}

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={details.mapLink}
                >
                  Google map
                </a>
              </ContactDetails>
            </FlexCol>
            <FlexCol xs={12} md={3}>
              <ContactDetails>
                <a href={`tel:${details.phoneNumber}`}>{details.phoneNumber}</a>
                <br />
                <a href={`mailto:${details.email}`}>{details.email}</a>
              </ContactDetails>
            </FlexCol>
          </FlexRow>
        </FlexGrid>
      </Contact>

      <StatementGrid gap={[0]} rowGap={[30, 50, 60]}>
        <FlexRow>
          <StatementCol xs={12} lg={6}>
            <div>
              <Flag fluid={data.abflag} />
              <Flag fluid={data.tsflag} />
              <p>{details.acknowledgement}</p>
            </div>
          </StatementCol>
        </FlexRow>
      </StatementGrid>

      <FlexGrid gap={[0]} rowGap={[30, 50, 60]}>
        <IconRow>
          <FlexCol xs={12} lg={8}>
            <Copyright>
              © {d.getFullYear()} Auburn Primary School. All Rights Reserved.{" "}
              <Link to="/privacy-policy">Privacy Policy.</Link>
            </Copyright>
          </FlexCol>
          {/* <FlexCol xs={12} lg={4}>
            <SocialAndLogo>
              <SocialLinks footer />
            </SocialAndLogo>
          </FlexCol> */}
        </IconRow>
      </FlexGrid>
    </>
  )
}
export default FooterMenu
