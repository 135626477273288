import React, { useState } from "react"
import NavItem from "./navItem"
import useMedia from "hooks/useMedia.js"
import Hamburger from "./hamburger"
import AvenueImg from "../AvenueImg"
import { useStaticQuery, graphql } from "gatsby"
import {
  LogoWrapper,
  NavBarContainer,
  NavWrapper,
  Nav,
  NavList,
  ImageWrapper,
} from "./style"

const NavBar = ({ className, preview }) => {
  const [isNavShown, setNavShown] = useState(false)
  const isMobile = useMedia(["(max-width: 768px)"], [true], false)

  const data = useStaticQuery(
    graphql`
      query {
        logos: allFile(
          filter: { absolutePath: { regex: "/logos/" } }
          sort: { fields: [name], order: ASC }
        ) {
          nodes {
            id
            name
            childImageSharp {
              fluid(maxWidth: 640, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }

        navBar: wpgraphql {
          pages(
            where: { orderby: { field: MENU_ORDER, order: ASC }, notIn: "2" }
          ) {
            nodes {
              title
              link
              childPages {
                nodes {
                  title
                  link
                }
              }
            }
          }
        }
      }
    `
  )

  const LogoImages = data.logos.nodes
  const homeNavBar = preview?.mainMenu.nodes || data.navBar.pages.nodes

  return (
    <NavBarContainer>
      <LogoWrapper to="/">
        <ImageWrapper>
          <AvenueImg
            noFade
            loading="eager"
            fluid={
              isMobile
                ? LogoImages[1].childImageSharp.fluid
                : LogoImages[0].childImageSharp.fluid
            }
          />
        </ImageWrapper>
      </LogoWrapper>
      <NavWrapper>
        <Nav isNavShown={isNavShown}>
          <NavList>
            {homeNavBar &&
              homeNavBar.map((item, index) => {
                return (
                  <NavItem
                    key={index}
                    item={item}
                    icon="arrow-down"
                    isLast={index === homeNavBar.length - 1}
                  />
                )
              })}
          </NavList>
        </Nav>
        <Hamburger
          isNavShown={isNavShown}
          toggleShowNav={() => setNavShown(!isNavShown)}
        />
      </NavWrapper>
    </NavBarContainer>
  )
}

export default NavBar
