import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"

export const NavBarContainer = styled(MainColumn)`
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 8px;

  @media ${device.tablet} {
    padding-top: 30px;
  }

  @media ${device.desktop} {
    padding-top: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(60, 60, 60, 0.3);
    z-index: 1;
  }
`

export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  height: auto;
  padding-top: 7px;

  @media ${device.tablet} {
    padding-top: 0;
  }
  @media ${device.desktop} {
    width: 25%;
  }
`

export const ImageWrapper = styled.div`
  width: 89px;

  @media ${device.tablet} {
    width: 263px;
  }
  @media ${device.desktop} {
    width: 200px;
  }
  @media ${device.large} {
    width: 320px;
  }
`

export const NavWrapper = styled.div`
  display: flex;
  padding-right: 10px;

  @media ${device.tablet} {
    width: 10%;
  }
  @media ${device.desktop} {
    width: 66%;
    justify-content: flex-end;
  }
`

export const Nav = styled.nav`
  position: absolute;
  display: flex;
  bottom: -3px;
  transform: translateY(100%);
  left: 0;
  margin: 0;
  background: ${props => props.theme.offWhite};
  width: calc(100% + 80px);
  margin-left: -40px;
  opacity: ${props => (props.isNavShown ? "1" : "0")};
  /* pointer-events: ${props => (props.isNavShown ? "all" : "none")}; */
  z-index: 5;
  border-top: ${props => (props.isNavShown ? "1px solid #D5D5D5 " : "none")};

  @media ${device.tablet} {
    bottom: -24px;
  }
  @media ${device.desktop} {
    opacity: 1;
    position: relative;
    bottom: auto;
    background: none;
    transform: none;
    width: 100%;
    border-top: none;
  }
`

export const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 170px;

  @media ${device.desktop} {
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 38px;
    padding-bottom: 0;
  }
  @media ${device.large} {
    padding-top: 60px;
  }
`

export const NavListItem = styled.li`
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -0.21px;
  line-height: 43px;
  color: ${props => props.theme.darkGreen};
  padding: 9px 40px;
  position: relative;
  width: 100%;
  cursor: pointer;
  svg {
    width: 21px;
    height: 20px;
  }

  :first-child {
    ::before {
      display: none;
    }
  }
  ::before {
    content: "";
    position: absolute;
    height: 1px;
    width: calc(100% - 80px);
    left: 40px;
    top: 0;
    background-image: linear-gradient(to right, #d5d5d5, #d5d5d5);
    @media ${device.desktop} {
      display: none;
    }
  }

  @media ${device.desktop} {
    font-size: 15px;
    letter-spacing: -0.22px;
    color: #3c3c3c;
    margin-right: 30px;
    padding: 0;
    width: auto;

    :last-child {
      margin-right: 0;
    }

    a:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 160%;
    }
  }
  @media ${device.large} {
    font-size: 18px;
  }

  a {
    display: block;
    text-align: left;
    color: inherit;
    position: relative;

    @media ${device.desktop} {
      ::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        opacity: 0;
        transition: 200ms;
        background-color: ${props => props.theme.linkColor};
      }

      :hover {
        :before {
          opacity: 1;
        }
      }
    }
  }
`

export const Clickable = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.desktop} {
    display: none;
  }
`

export const HamburgerWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 16px;
  position: relative;

  cursor: pointer;
`

export const FirstLine = styled.span`
  display: block;
  height: 2px;
  width: 100%;
  transition: 0.4s ease-out;
  background: black;
  position: absolute;
  transform: ${props =>
    props.isNavShown
      ? "translate3d(0, 6px, 0) rotate(45deg)"
      : "translate3d(0, 0, 0) rotate(0deg)"};
`

export const SecondLine = styled(FirstLine)`
  transition: 0.1s ease-out;
  opacity: ${props => (props.isNavShown ? 0 : 1)};
  top: 8px;
`

export const ThirdLine = styled(FirstLine)`
  transform: ${props =>
    props.isNavShown
      ? "translate3d(0, 6px, 0) rotate(-45deg)"
      : "translate3d(0, 16px, 0) rotate(0deg)"};
  display: block;
`

export const LinkIcon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${device.desktop} {
    display: block;
    position: relative;
  }

  span {
    color: ${props => (props.menuShown ? "#000" : "#026747")};
    white-space: nowrap;
  }

  svg {
    transition: 330ms;
    transform: ${props => (props.menuShown ? "rotate(180deg)" : "rotate(0)")};
    path {
      fill: ${props => (props.menuShown ? "#000" : "#026747")};
    }
    @media ${device.desktop} {
      display: none;
    }
  }
`

export const Submenu = styled.ul`
  list-style: none;
  display: ${props => (props.menuShown ? "flex" : "none")};
  flex-direction: column;
  z-index: 5;
  margin-top: 8px;
  transition: 330ms;
  margin-left: 0;

  @media ${device.desktop} {
    display: none;
    position: absolute;
    top: 44px;
    margin-top: 0;
    background: #ffffff;
    width: 340px;
    ${props => (props.isLast ? "right: 0" : "left: 0;")};
    z-index: 0;

    :hover & {
      display: block;
    }

    ${NavListItem}:hover & {
      display: block;
    }
  }
  li {
    margin-left: 10px;
    margin-right: 10px;
    position: relative;

    :first-child {
      margin-top: 10px;
    }
    :last-child {
      margin-bottom: 10px;
    }
  }

  a {
    font-weight: 400;
    font-size: 20px;
    color: #043828;
    letter-spacing: -0.19px;
    line-height: 43px;

    @media ${device.desktop} {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: -0.2px;
      border-radius: 19px;
      font-size: 16px;
      color: #10523d;
      letter-spacing: -0.2px;
      padding-left: 25px;
      text-align: left;

      :hover {
        background: #f2efe9;
        color: #3c3c3c;
        :before {
          display: none;
        }
      }
    }
  }
`
