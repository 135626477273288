import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"


export const SocialLinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 0;
  border-top: 1px solid rgba(60, 60, 60, 0.3);
  border-bottom: 1px solid rgba(60, 60, 60, 0.3);

  @media ${device.desktop} {
    border: 0;
    padding: 0;
    justify-content: flex-end;
  }
  a {
    margin-bottom: 6px;
    :hover {
      opacity: 0.6;
      transition: 70ms;
    }

    svg {
      display: block;
      height: 36px;
      width: auto;

      @media ${device.desktop} {
        height: 23px;
      }
      g,
      path {
        fill: #043828;
      }

      :hover {
        opacity: 0.6;
        transition: 70ms;
      }
    }
  }
`
