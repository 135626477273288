import React from "react"
import FooterMenu from "./footerMenu"

import { Container } from "./footerStyles"

const Footer = ({ preview }) => (
  <Container>
    <FooterMenu preview={preview} />
  </Container>
)
export default Footer
