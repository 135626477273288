import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import theme from "../utils/theme.js"
import { ThemeProvider } from "styled-components"
import { Header } from "./Header"
import { Footer } from "./Footer"
import Helmet from "react-helmet"
import { useEffect } from "react"

const Layout = ({ children, preview, pageContext }) => {
  useEffect(() => {
    console.log("yolo")
    const script = document.createElement("script")
    script.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    // script.async = true

    document.body.appendChild(script)

    return function cleanUp() {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <script
          type="text/javascript"
          data-avenue={`gleam-${pageContext.link}`}
        >
          {`  console.log('re-renderme')

  function googleTranslateElementInit() {
    console.log('googletranslateelement')
    new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
  }
  `}
        </script>
      </Helmet>

      <div
        id="google_translate_element"
        style={{
          position: "fixed",
          zIndex: 100,
          bottom: "20px",
          right: "20px",
          background: "#fff",
          border: "1px solid #303030",
          padding: "10px",
        }}
      ></div>

      <div className="app-wrapper">
        <Header preview={preview} />
        <main>{children}</main>
        <Footer preview={preview} />
      </div>
     
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
