import styled from "styled-components"
import { device } from "breakpoints"

export const HeaderContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.offWhite};
  /* max-width: 1660px; */
  position: relative;
  margin: 0 auto;
  min-height: 106px;

  @media ${device.tablet} {
    padding-bottom: 25px;
    padding-bottom: 25px;
  }
  @media ${device.desktop} {
    padding-bottom: 25px;
  }
`
