import React, { useState } from "react"
import { Link } from "gatsby"
import { Icon } from "../../utils/helpers/getIcon"
import useMedia from "hooks/useMedia.js"
import { NavListItem, Submenu, LinkIcon } from "./style"

const NavBar = ({ item, icon, isLast }) => {
  const [menuShown, setMenuShown] = useState(false)
  const isMobile = useMedia(["(max-width: 768px)"], [true], false)

  function handleClick() {
    setMenuShown(!menuShown)
  }
  return (
    <NavListItem onClick={() => (isMobile ? handleClick() : null)}>
      <LinkIcon menuShown={menuShown}>
        <Link to={item.link}>
          <span dangerouslySetInnerHTML={{ __html: item.title }} />
        </Link>
        <Icon name={icon} />
      </LinkIcon>

      {item.childPages.nodes && (
        <Submenu menuShown={menuShown} isLast={isLast}>
          {item.childPages.nodes.map((submenu, index) => {
            return (
              <li key={index}>
                <a href={submenu.link}>{submenu.title}</a>
              </li>
            )
          })}
        </Submenu>
      )}
    </NavListItem>
  )
}

export default NavBar
