import React from "react"

import { NavBar } from "../NavBar"
import { HeaderContainer } from "./style"
import { Link } from "gatsby"
import AvenueImg from "components/AvenueImg"

const Header = ({ preview }) => {
  return (
    <HeaderContainer>
      <NavBar preview={preview} />
    </HeaderContainer>
  )
}

export default Header
