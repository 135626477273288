const primaryColor = "#026747"
const white = "#FFFFFF"

const theme = {
  primaryColor: primaryColor,
  linkColor: primaryColor,
  btnColor: primaryColor,
  btnHoverColor: "#84004b",
  green: "#026747",
  darkGreen: "#043828",
  offWhite: "#F2EFE9",
  lightGrey: "#D8D8D8",
  grey: "#3C3C3C",
  btnTextColor: white,
  mobileGutter: 40,
  tabletGutter: 30,
  desktopGutter: 30,
  mainMaxWidth: 1170,
  mobileGap: 15,
  tabletGap: 20,
  desktopGap: 25,
  largeGap: 30,
}

export default theme
